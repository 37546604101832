import React, { FC, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Form } from '../../../../../Components';
import { FormFieldValue, FormValues } from '../../../../../Components/Form/Form.types';
import { useFormField } from '../../../../../Components/Form/FormField';
import { AptorApi, INamedEntity } from '../../../../../Api/AptorApi';
import { useAptorApi } from '../../../../../Api';
import { useConfirmation } from '../../../../../Context/ConfirmationContext/ConfirmationContext';
import { entityToSelectOption } from '../../../../../Utilities';

interface IPropsView {
  replacedLaws?: any;
  submit: (replacementId: number) => void;
}
export const ReplaceLawView: FC<IPropsView> = ({ replacedLaws, submit }) => {
  if (replacedLaws?.length === 0)
    return (
      <Typography>
        <FormattedMessage
          id="admin.law-portal.law.form.replacesLaw.missing"
          defaultMessage="No law is replaced by this law"
        />
      </Typography>
    );
  return (
    <>
      {replacedLaws?.map((item: any, index: never) => {
        return (
          <div key={index}> <Typography>{item?.name}</Typography>
            <Button variant="text" color="default" onClick={() => submit(item.replacementId)} startIcon={<Delete />}></Button>
          </div>
        )
      })}
    </>
  );
};

interface IPropsForm {
  id: number;
  replacedLaws:any;
  submit: (value: INamedEntity, api: AptorApi) => Promise<void>;
}
export const ReplaceLawForm: FC<IPropsForm> = ({ id,replacedLaws, submit }) => {
  const { formatMessage } = useIntl();
  const { api, abortController } = useAptorApi();
  const { deleteConfirmation } = useConfirmation();
  const loadOptions = useCallback(
    async (search) => {
      const result = await api.searchAvailableLaws({ id, search });
      if (abortController.current.signal.aborted) {
        return undefined;
      }
      let replacedLawsIDAdd=replacedLaws?.map((item:any)=> item?.id)
      let resultData=result.items?.filter((item:any)=>{
        if(!replacedLawsIDAdd?.includes(item.id)){
          return item
        }
      })
      return resultData.map(entityToSelectOption);
    },
    [id, api, abortController],
  );

  const replacesLawField = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.replacesLaw.select', defaultMessage: 'Replaces law' }),
    name: 'replacedLawId',
    type: 'single-select-async',
    loadOptions: loadOptions,
    required: true,
  });

  const onSubmit = async (form: FormValues, api: AptorApi, onSuccess: (msg: null) => void) => {

    let selection = replacesLawField.state[0] as FormFieldValue;
    let lawName = selection.label as string;
    let lawId = form['replacedLawId'] as number;
    let replacedlaw = { id: lawId, name: lawName };
    // setOpen(true)
    // setItemData(replacedlaw)
    const onReplace = async () => {
      let lawId = form['replacedLawId'] as number;
      if (selection && lawName && !isNaN(lawId) && selection.value === lawId) {
        let replacedlaw = { id: lawId, name: lawName };
        await submit(replacedlaw, api);
        onSuccess(null);
      }
    };

    const onCancel = () => onSuccess(null);

    deleteConfirmation(
      onReplace,
      {
        description: formatMessage({ id: 'admin.law-portal.law.form.replacesLaw.confirmation' }, { law: lawName }),
      },
      onCancel,
    );
  };

  return  <Form submit={onSubmit} groups={[{ grid: { type: 'row', items: [replacesLawField] } }]} />;
};
